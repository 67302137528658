import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../ui/Logo";
import "./Header.css";
import NewsletterModal from "../ui/NewsletterModal";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [token, setToken] = useState("");
  const [showNewsleterModal, setShowNewsletterModal] = useState(false);

  const SERVICES = [
    "Courier",
    "Air Freight",
    "Sea Freight",
    "Rail Freight",
    "Road Transport",
  ];

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleShowModal = () => {
    closeMobileMenu()
    setShowNewsletterModal(true);
  }

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
  }, []);

  return (
    <header>
      <div id="hideshow-sticky-menu">
        <div
          id="theme-menu-one"
          className="main-header-area menu-style-2 pt-20 pb-15 px-20"
        >
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-3 col-lg-3 col-6">
                <Logo />
              </div>
              <div className="col-xl-6 col-lg-6 text-start d-none d-lg-inline-block">
                <nav id="topheader" className="navbar navbar-expand-lg justify-content-center">
                  <div className="nav-container">
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav list-style-none">
                      <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="/"
                            id="navbarDropdown3"
                            role="button"
                          >
                            Home
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to="/trackparcels"
                            id="navbarDropdown3"
                            role="button"
                          >
                            Track &amp; Trace
                          </Link>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="/all-services"
                            id="navbarDropdown4"
                            role="button"
                            data-bs-toggle="dropdown"
                          >
                            Services
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown4"
                          >
                            {SERVICES.map((service, index) => (
                              <li key={index}>
                                <Link
                                  className="dropdown-item"
                                  to={`/quote/${service
                                    .toLowerCase()
                                    .replace(/\s+/g, "-")}`}
                                >
                                  {service}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        {/*<li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            id="navbarDropdown5"
                            role="button"
                            data-bs-toggle="dropdown"
                          >
                            Company
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown5"
                          >
                            <li>
                              <Link className="dropdown-item" to="#">
                                About Us
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/faq">
                                FAQ
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/contact-us">
                                Contact Us
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            id="navbarDropdown6"
                            role="button"
                            data-bs-toggle="dropdown"
                          >
                            Resources
                          </Link>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="navbarDropdown6"
                          >
                            <li>
                              <Link className="dropdown-item" to="/blog">
                                Blog
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="#" onClick={() => setShowNewsletterModal(true)}>
                                Newsletter
                              </Link>
                            </li>
                          </ul>
                        </li>*/}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="col-xl-3 col-lg-3 col-6">
                <div className="right-nav d-flex align-items-center justify-content-end">
                  <ul className="list-style-none right-btn align-items-center gap-3 d-none d-md-flex">
                    {/* <li className="d-flex align-items-center">
                      <Link
                        className=" theme_btn_border "
                        to="/login"
                        id="navbarDropdown3"
                        style={{
                          fontWeight: 500,
                          fontSize: "18px",
                        }}
                      >
                        Login
                      </Link>
                    </li>
                    <li className="d-flex align-items-center">
                      <Link className=" theme_btn" to="/signup">
                        <span>Create Account</span>
                      </Link>
                    </li> */}
                    {!token ? (
                      <>
                        <li className="d-flex align-items-center">
                          <Link
                            className="theme_btn_border "
                            to="/login"
                            id="navbarDropdown3"
                            style={{
                              fontWeight: 500,
                              fontSize: "18px",
                            }}
                          >
                            Login
                          </Link>
                        </li>
                        <li className="d-flex align-items-center">
                          <Link className=" theme_btn" to="/signup">
                            <span>Create Account</span>
                          </Link>
                        </li>
                      </>
                    ) : (
                      <>
                        <Link className=" theme_btn" to="/dashboard">
                          <span>Dashboard</span>
                        </Link>
                      </>
                    )}
                  </ul>

                  <div
                    className="hamburger-menu d-md-inline-block d-lg-none text-right ml-15"
                    onClick={toggleMobileMenu}
                  >
                    <i className="far fa-bars" style={{ fontSize: "24px" }}></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NewsletterModal show={showNewsleterModal} onClose={() =>  setShowNewsletterModal(false)} />

      {/* {!showNewsleterModal && <NewsletterModal show={!showNewsleterModal} onClose={setShowNewsletterModal(false)} />} */}
      </div>
      {/* Slide Bar Start */}
      <aside className={`slide-bar ${isMobileMenuOpen ? "open" : ""}`}>
        <div
          className="close-mobile-menu"
          style={{ color: "#ccc" }}
          onClick={closeMobileMenu}
        >
          <Link to="/">
            <i className="fas fa-times"></i>
          </Link>
        </div>
        <nav className="side-mobile-menu">
          <ul id="mobile-menu-active" style={{ color: "#ccc" }}>
            <li>
              <Link to="/" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/trackparcels" onClick={closeMobileMenu}>
                Track and Trace
              </Link>
            </li>
            <li className="has-dropdown">
              <Link to="#">Services</Link>
              <ul className="sub-menu" style={{ color: "#ccc" }}>
                {SERVICES.map((service) => (
                  <li key={service}>
                    <Link
                      className="dropdown-item"
                      to={`/quote/${service
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                      onClick={closeMobileMenu}
                    >
                      {service}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            {/*<li className="has-dropdown">
              <Link to="#">Company</Link>
              <ul className="sub-menu" style={{ color: "#ccc" }}>
                <li>
                  <Link to="#" onClick={closeMobileMenu}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={closeMobileMenu}>
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" onClick={closeMobileMenu}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </li>*/}
            <li className="has-dropdown">
              <Link to="#">Resources</Link>
              <ul className="sub-menu" style={{ color: "#ccc" }}>
                <li>
                  <Link to="/blog" onClick={closeMobileMenu}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="#" onClick={handleShowModal}>
                    Newsletter
                  </Link>
                </li>
              </ul>
            </li>
            {!token ? (
              <>
                <li>
                  <Link
                    className="theme_btn_full "
                    to="/login"
                    style={{
                      fontWeight: 500,
                      fontSize: "18px",
                      background: "#fff",
                      border: "1px solid #000066"
                    }}
                  >
                    Login
                  </Link>
                </li>
                <li>
                  <Link
                    to="/signup"
                    className="theme_btn_full"
                    onClick={closeMobileMenu}
                    style={{ color: "#fff" }}
                  >
                    Create Account
                  </Link>
                </li>
              </>
            ) : (
              <>
                <Link
                  to="/dashboard"
                  className="theme_btn_full"
                  onClick={closeMobileMenu}
                  style={{ color: "#fff" }}
                >
                  Dashboard
                </Link>
              </>
            )}
          </ul>
        </nav>
      </aside>
      <div
        className={`body-overlay ${isMobileMenuOpen ? "active" : ""}`}
        onClick={closeMobileMenu}
      ></div>

      
      {/* Slide Bar End */}
    </header>
  );
};

export default Header;
